<template>
    <div>
    
        <div class="item">
                    <div class="property_item heading_space">
                      <div class="image">
                        <div class="webkit-animation webkit-100per-250"></div>
                        <div class="feature">
                          <span class="tag-2 webkit-animation webkit-50per-50"></span>
                        </div>
                        <div class="price clearfix">
                          <span class="tag pull-right webkit-animation webkit-100per-50"></span>
                        </div>
                      </div>
                    </div>
                  </div>
        
    </div>
</template>
<script>    
export default {
    name: 'BestDealLoading',
    props: [],
    data() {
        return {
            //
        }
    }
}
</script>